import { createSelector } from 'reselect';

// @ts-expect-error migrate upstream
import { selectAllGates } from './index';
import { gate } from 'hub-http/gates';
import { userInfoSync } from 'hub-http/userInfo';
export const selectUserHasGates = (...gates) => createSelector(selectAllGates, gatesList => gates.every(g => gatesList.includes(g)));

// v3 gates aren't backed by redux, and are instead retrieved from the user-info package
// v3 gates will become the new norm https://product.hubteam.com/docs/gating/next-gen/overview.html
const selectUserHasV3Gate = gateId => {
  try {
    const {
      gates
    } = userInfoSync();
    return gates.includes(gateId);
  } catch (err) {
    return false;
  }
};
export const selectHasSalesExplorerExpGate = selectUserHasGates('RA:SalesExplorerExp');
export const selectHasSetBusinessUnitMigrationAccess = selectUserHasGates('RM:SetBusinessUnitMigration');
export const selectHasSetBusinessUnitDBUIMigrationAccess = selectUserHasGates('RM:SetBusinessUnitDBUIMigration');
export const selectHasSetDefaultDashboardMigrationAccess = selectUserHasGates('RM:SetDefaultDashboardMigration');
export const selectHasCSATSurvey = selectUserHasGates('RM:CSATSurvey');
export const getIsUngatedToShareWithComponent = selectUserHasGates('IntegrationComponents:PublicApps:ShareWithComponent');
export const selectHasDashboardFiltersInSRV = () => selectUserHasV3Gate(gate('RM:DashboardFiltersInSRV'));
export const selectHasGranularPermissionsAccess = () => selectUserHasV3Gate(gate('Dashboards:GranularPermissionsGate'));
export const selectHasDataFirstFlowAccess = () => selectUserHasV3Gate(gate('ReportCreation:DataFirstCreationFlow'));
export const selectHasDashboardUIReportCardV2Access = () => selectUserHasV3Gate(gate('RM:DashboardUIReportCardV2'));
export const selectHasReportingInsightsCopilotAccess = () => selectUserHasV3Gate(gate('ReportingPlatform:ReportingInsightsCopilotTool'));
export const selectHasAddSectionHeaderToDBUIAccess = () => selectUserHasV3Gate(gate('RM:AddSectionHeaderToDBUI'));
export const selectHasEmbedDashboardsAccess = () => selectUserHasV3Gate(gate('RM:EmbeddedDashboards'));
export const selectHasDashboardSRVTabsAccess = () => selectUserHasV3Gate(gate('ReportingEnablement:dashboardTabs'));
export const selectHasUnifiedFilterDashboard = () => selectUserHasV3Gate(gate('RM:UnifiedFilterDashboardUI'));
export const selectHasDashboardPanelAccess = () => selectUserHasV3Gate(gate('RH:DashboardCreationPanel'));
export const selectHasReportSetupAccess = () => selectUserHasV3Gate('Reporting:ReportSetup');