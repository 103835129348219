import { getFilterSetById } from '../../lib/savedDashboardFilters';
import { createSelector } from 'reselect';
import { getSavedFilterStateForDashboard } from './helpers';
import { savedDashboardFiltersNamespace } from './slice';
import { applyInvalidPropertyFlagToDashboardFilters, filterOutDashboardFiltersWithInvalidProperties } from '../../lib/dashboardFilters/groupQuickFilterHelpers';
import { selectDataSourcePropertiesView } from '../platform-data-source-metadata';

// selectors

function withState(selector) {
  return state => selector(state[savedDashboardFiltersNamespace]);
}
function withDashboardId(selector, notFoundReturn) {
  return dashboardId => withState(state => {
    const maybeSavedFilterState = getSavedFilterStateForDashboard(state, dashboardId);
    if (maybeSavedFilterState) {
      return selector(maybeSavedFilterState);
    }
    return notFoundReturn;
  });
}
const _selectFilterSets = withDashboardId(filterState => filterState.filterSets, []);
const _selectSavedFilterSetsWithInvalidPropertyFlags = dashboardId => createSelector([_selectFilterSets(dashboardId), selectDataSourcePropertiesView], (filterSets, dataSourceProperties) => {
  return filterSets.map(filterSet => {
    return Object.assign({}, filterSet, {
      filters: applyInvalidPropertyFlagToDashboardFilters(filterSet.filters, dataSourceProperties)
    });
  });
});

/** Contains filter sets that only contain filter with valid properties
 * Can be used for applying to reports and persisting
 */
export const selectSavedFilterSets = dashboardId => createSelector([_selectSavedFilterSetsWithInvalidPropertyFlags(dashboardId)], filterSets => {
  return filterSets.map(filterSet => {
    return Object.assign({}, filterSet, {
      filters: filterOutDashboardFiltersWithInvalidProperties(filterSet.filters)
    });
  });
});
export const selectFilterSetById = (dashboardId, filterSetId) => createSelector(selectSavedFilterSets(dashboardId), filterSets => getFilterSetById(filterSets, filterSetId));