import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { getDashboardDataSources } from 'platform-dashboard-ui/lib/dashboardDataSourceUtils';
import { useDataSourceMetaWithLoading } from 'platform-dashboard-ui/hooks/use-data-source-metadata';
import { useDataSourceProperties } from 'platform-dashboard-ui/hooks/use-data-source-properties';
// @ts-expect-error migrate upstream
import { getId as getDashboardId } from 'dashboard-lib/public/dashboard/dashboard-schema';
import { dashboardReportsActions } from '../ducks/dashboard-reports';
import { initializeDashboardFilters } from '../ducks/applied-dashboard-filters';
import { getSavedFilterSetIdQueryParam } from '../lib/dashboardFilters/dashboard-filter-query-params-util';
import { fetchSavedFilterById, getFilterSetById, transformSavedFilterToDashboardFilter } from '../lib/savedDashboardFilters';
import { buildFailedAsyncData, buildStartedAsyncData, buildSucceededAsyncData, buildUnitializedAsyncData, isFailed, isLoading, isSucceeded, isUninitialized } from 'reporting-data/asyncData/AsyncData';
import { DYNAMIC_DATE_RANGE_PROPERTY_NAME } from '../lib/dashboardFilters/types';
import { List } from 'immutable';
import { QUICK_FILTER_TEMPLATES, isDateRangeGeneratedQuickFilterGroup } from '../lib/dashboardFilters/groupQuickFilterHelpers';
import { fetchGeneratedQuickFilterGroups } from 'platform-dashboard-ui/filter/data/QuickFilterDAO';
// @ts-expect-error migrate upstream
import { getPinnedPropertyGroups } from 'reporting-action-components/data/schemas/dashboardSchema';
const useShouldInitializeSavedDashboardFilters = () => {
  const history = useHistory();
  const filterSetIdOverride = getSavedFilterSetIdQueryParam({
    history
  });
  return !!filterSetIdOverride;
};

// TODO split out into another data fetching hook once property metadata store API is ready
export const useFetchDataSourcesForDashboard = dashboard => {
  const dataSourceIds = getDashboardDataSources(dashboard);
  const {
    dataSourceMetaData: dataSourcesMetadata,
    isLoading: isDataSourceMetaLoading
  } = useDataSourceMetaWithLoading(dataSourceIds);
  const {
    propertiesByDataSource: dataSourcesProperties,
    isLoading: isDataSourcePropertiesLoading
  } = useDataSourceProperties(dataSourceIds);
  const isDataSourceDataLoading = isDataSourceMetaLoading || isDataSourcePropertiesLoading;
  const isLoaded = !isDataSourceDataLoading;
  return {
    isLoaded,
    dataSourcesMetadata,
    dataSourcesProperties
  };
};
export const useInitializeFilters = (dashboard, shouldInitializeDashboardFilters) => {
  const dashboardId = getDashboardId(dashboard);
  const quickFilters = (getPinnedPropertyGroups(dashboard) || List()).toJS();
  const history = useHistory();
  /** dashboard id of filters to initialize */
  const [initializedDashboardId, setInitializedDashboardId] = useState();
  const dispatch = useDispatch();
  const [fetchEmailOnlyFilterSetAsyncData, setFetchEmailOnlyFilterSetAsyncData] = useState(buildUnitializedAsyncData());
  const hasInitializedFilters = initializedDashboardId === dashboardId;
  const dynamicDateRangeFilterGroup = quickFilters.find(isDateRangeGeneratedQuickFilterGroup);
  const [dynamicDateRangePropertiesAsyncState, setDynamicDateRangePropertiesAsyncState] = useState(dynamicDateRangeFilterGroup ? buildSucceededAsyncData(dynamicDateRangeFilterGroup.properties) : buildUnitializedAsyncData());
  const shouldIntialize = shouldInitializeDashboardFilters && !hasInitializedFilters;
  const shouldInitializeSavedFilters = useShouldInitializeSavedDashboardFilters();
  const shouldIntializeBEFilters = shouldInitializeDashboardFilters && hasInitializedFilters;
  useEffect(() => {
    if (shouldIntializeBEFilters) {
      dispatch(dashboardReportsActions.initializeDashboardReports({
        dashboard
      }));
    }
    //  prevent running this whenever dashboard object changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, shouldIntializeBEFilters]);
  const {
    isLoaded: areDataSourceDependenciesLoaded,
    dataSourcesMetadata,
    dataSourcesProperties
  } = useFetchDataSourcesForDashboard(dashboard);
  const fetchDynamicDateRangeProperties = useCallback(() => {
    setDynamicDateRangePropertiesAsyncState(buildStartedAsyncData());
    fetchGeneratedQuickFilterGroups(dashboardId, [QUICK_FILTER_TEMPLATES.DATE_RANGE]).then(response => {
      const dateRangeQuickFilterGroup = response.find(isDateRangeGeneratedQuickFilterGroup);
      const nextDateRangeProperties = dateRangeQuickFilterGroup ? dateRangeQuickFilterGroup.properties : [];
      setDynamicDateRangePropertiesAsyncState(buildSucceededAsyncData(nextDateRangeProperties));
    }).catch(error => {
      console.error(error);
      setDynamicDateRangePropertiesAsyncState(buildSucceededAsyncData([]));
    });
  }, [dashboardId]);
  useEffect(() => {
    if (initializedDashboardId && dashboardId !== initializedDashboardId) {
      setFetchEmailOnlyFilterSetAsyncData(buildUnitializedAsyncData());
      setInitializedDashboardId(undefined);
    }
  }, [dashboardId, initializedDashboardId]);
  useEffect(() => {
    if (!shouldIntialize) {
      return;
    }
    if (!areDataSourceDependenciesLoaded) {
      return;
    }
    if (!shouldInitializeSavedFilters) {
      dispatch(initializeDashboardFilters({
        dashboardId,
        history,
        quickFilters
      }));
      setInitializedDashboardId(dashboardId);
      return;
    }
    const apiSavedFilterSets = dashboard.get('filters').toJS();
    const emailOnlyFilterSetContainsDynamicDateRangeProperties = (() => {
      if (isSucceeded(fetchEmailOnlyFilterSetAsyncData)) {
        return fetchEmailOnlyFilterSetAsyncData.data.filters.some(filter => filter.name === DYNAMIC_DATE_RANGE_PROPERTY_NAME);
      }
      return false;
    })();
    const shouldHydrateDynamicDateRangeProperties = apiSavedFilterSets.some(filterSet => filterSet.filters.some(filter => filter.name === DYNAMIC_DATE_RANGE_PROPERTY_NAME)) || emailOnlyFilterSetContainsDynamicDateRangeProperties;
    if (shouldHydrateDynamicDateRangeProperties && isUninitialized(dynamicDateRangePropertiesAsyncState)) {
      fetchDynamicDateRangeProperties();
      return;
    }
    if (shouldHydrateDynamicDateRangeProperties && isLoading(dynamicDateRangePropertiesAsyncState)) {
      return;
    }
    const transformedFilterSets = apiSavedFilterSets.map(filterSet => Object.assign({}, filterSet, {
      filters: transformSavedFilterToDashboardFilter(filterSet.filters, dataSourcesProperties, dataSourcesMetadata, isSucceeded(dynamicDateRangePropertiesAsyncState) ? dynamicDateRangePropertiesAsyncState.data : [])
    }));
    const filterSetIdOverride = getSavedFilterSetIdQueryParam({
      history
    });
    const hasFilterSetOverrideInSavedFilterSets = filterSetIdOverride && getFilterSetById(transformedFilterSets, filterSetIdOverride);
    if (hasFilterSetOverrideInSavedFilterSets || !filterSetIdOverride) {
      dispatch(initializeDashboardFilters({
        dashboardId,
        history,
        filterSetId: filterSetIdOverride,
        filterSets: transformedFilterSets,
        quickFilters
      }));
      setInitializedDashboardId(dashboardId);
      return;
    }

    // Load email only filter set by id
    if (isUninitialized(fetchEmailOnlyFilterSetAsyncData)) {
      setFetchEmailOnlyFilterSetAsyncData(buildStartedAsyncData());
      fetchSavedFilterById(filterSetIdOverride).then(response => {
        setFetchEmailOnlyFilterSetAsyncData(buildSucceededAsyncData(response));
      }).catch(error => {
        setFetchEmailOnlyFilterSetAsyncData(buildFailedAsyncData(error));
      });
      return;
    }

    // initialize dashboard filters w/ email only filter override
    if (isSucceeded(fetchEmailOnlyFilterSetAsyncData)) {
      const emailOnlyFilterSet = Object.assign({}, fetchEmailOnlyFilterSetAsyncData.data, {
        filters: transformSavedFilterToDashboardFilter(fetchEmailOnlyFilterSetAsyncData.data.filters, dataSourcesProperties, dataSourcesMetadata, isSucceeded(dynamicDateRangePropertiesAsyncState) ? dynamicDateRangePropertiesAsyncState.data : [])
      });
      dispatch(initializeDashboardFilters({
        dashboardId,
        history,
        filterSetId: filterSetIdOverride,
        filterSets: [emailOnlyFilterSet, ...transformedFilterSets],
        quickFilters
      }));
      setInitializedDashboardId(dashboardId);
      return;
    }

    // initialize dashboard filters w/o filter override
    if (isFailed(fetchEmailOnlyFilterSetAsyncData)) {
      dispatch(initializeDashboardFilters({
        dashboardId,
        history,
        filterSets: transformedFilterSets,
        quickFilters
      }));
      setInitializedDashboardId(dashboardId);
      return;
    }
  }, [areDataSourceDependenciesLoaded, dashboard, dashboardId, dataSourcesMetadata, dataSourcesProperties, dispatch, dynamicDateRangePropertiesAsyncState, fetchEmailOnlyFilterSetAsyncData, history, shouldInitializeSavedFilters, shouldIntialize, quickFilters, fetchDynamicDateRangeProperties]);
  return hasInitializedFilters;
};