import { Map as ImmutableMap } from 'immutable';
import { ASSOCIATION_FIELD_NAME } from './association-utils';
import { getFieldLabel, getIconFromField, fromSnowflakeProperty } from './column-utils';
import { ROW_ID, OBJECT_ID } from '../constants/shared';
import { BasicTypes, FieldTypes, Field as FieldRecord } from '../schema/column-records';
import { FieldRef } from '../schema/field-ref';
import { sortOptionsByText } from '../../shared/lib/utils';
import { isDangerousRollupProperty, shouldHideProperty, getDangerousRollupPropertyTableLabel, maybeGetObjectTypeId } from './source-utils';
import { getTableLabelWithSelfJoins, getTableNames } from './table-utils';
export const isFieldNameMagicId = name => name === ROW_ID || name === OBJECT_ID;
export const convertFieldToFieldRef = field => field.table ? FieldRef({
  property: field.name,
  table: field.table
}) : undefined;
const {
  DATE,
  DATETIME,
  STRING,
  NUMBER,
  BOOL,
  ENUMERATION,
  PHONE_NUMBER,
  CURRENCY_NUMBER,
  JSON,
  OBJECT_COORDINATES
} = FieldTypes;
export const FieldTypeToIconValue = {
  [STRING]: 'textDataType',
  [NUMBER]: 'numericDataType',
  [DATE]: 'dateDataType',
  [BOOL]: 'booleanDataType',
  [ENUMERATION]: 'textDataType',
  [DATETIME]: 'dateDataType',
  [PHONE_NUMBER]: 'calling',
  [CURRENCY_NUMBER]: 'currency',
  [OBJECT_COORDINATES]: 'textDataType',
  [JSON]: 'textDataType'
};
export const getBasicType = field => {
  switch (field && field.type) {
    case FieldTypes.NUMBER:
    case FieldTypes.CURRENCY_NUMBER:
      return BasicTypes.NUMBER;
    case FieldTypes.BOOL:
      return BasicTypes.BOOLEAN;
    case FieldTypes.DATE:
      return BasicTypes.DATE;
    case FieldTypes.DATETIME:
      return BasicTypes.DATETIME;
    default:
      return BasicTypes.STRING;
  }
};
export const getFieldSections = ({
  tableDescription,
  properties,
  tables,
  allJoinMetadata,
  reportFieldRefs,
  hasFlexibleAssociationsAccess
}) => {
  if (!tables || !properties || !allJoinMetadata) {
    return ImmutableMap().merge({
      '': {
        heading: {
          id: '',
          text: ''
        },
        fields: []
      }
    });
  }
  const tableNames = getTableNames(tableDescription);
  const isHiddenPropertyUsedInReport = (snowflakeProperty, tableName) => {
    return !!shouldHideProperty(snowflakeProperty) && reportFieldRefs.some(fieldRef => tableName === fieldRef.table && snowflakeProperty.name === fieldRef.property);
  };
  return ImmutableMap().withMutations(sections => {
    tableNames.forEach(tableName => {
      const tableProperties = properties.get(tableName) || ImmutableMap();
      const snowflakeTable = tables.get(tableName);
      if (!snowflakeTable) {
        sections.set(tableName, {
          heading: {
            id: `${tableName}.loading-field-list-section`,
            text: 'Loading...'
          },
          fields: []
        });
        return;
      }
      const sourceLabel = getTableLabelWithSelfJoins(tableName, tableDescription, snowflakeTable, allJoinMetadata);
      const objectTypeId = tables.getIn([tableName, 'objectTypeId']);
      const {
        rolloutStage
      } = snowflakeTable;
      const associationProperty = tableProperties.get(ASSOCIATION_FIELD_NAME);
      const propertiesWithoutAssociation = associationProperty ? tableProperties.delete(ASSOCIATION_FIELD_NAME) : tableProperties;
      let fields = propertiesWithoutAssociation.filter(snowflakeProperty => !shouldHideProperty(snowflakeProperty) || isHiddenPropertyUsedInReport(snowflakeProperty, tableName)).map(snowflakeProperty => {
        const field = FieldRecord({
          name: snowflakeProperty.name,
          table: tableName,
          type: snowflakeProperty.type,
          source: 'TABLE'
        });
        const text = getFieldLabel(field, snowflakeProperty);
        const icon = getIconFromField(field);
        const propertyGroupName = snowflakeProperty.groupName;
        const warningMessage = isDangerousRollupProperty(tableDescription, snowflakeProperty) ? {
          type: 'rollup',
          tableName: getDangerousRollupPropertyTableLabel(tableDescription, tables.toList(), snowflakeProperty)
        } : undefined;
        const errorMessage = isHiddenPropertyUsedInReport(snowflakeProperty, tableName) ? 'Invalid property' : undefined;
        return {
          text,
          value: field,
          icon,
          sourceLabel,
          errorMessage,
          warningMessage,
          objectTypeId,
          propertyGroupName,
          isFlpPermissioned: snowflakeProperty.flpRestricted
        };
      }).sort(sortOptionsByText).toArray();

      // Add back association property if it exists and user has access
      if (associationProperty && hasFlexibleAssociationsAccess) {
        const field = fromSnowflakeProperty(associationProperty, tableName);
        const text = getFieldLabel(field, associationProperty);
        const icon = getIconFromField(field);
        const associationObjectTypeId = maybeGetObjectTypeId(snowflakeTable) || undefined;
        const associationField = {
          text,
          value: FieldRecord({
            name: ASSOCIATION_FIELD_NAME,
            table: tableName,
            type: associationProperty.type,
            source: 'ASSOCIATION'
          }),
          icon,
          sourceLabel,
          objectTypeId: associationObjectTypeId,
          propertyGroupName: 'none',
          errorMessage: undefined,
          warningMessage: undefined,
          isFlpPermissioned: false
        };
        fields = [associationField, ...fields].sort(sortOptionsByText);
      }
      if (fields.length > 0) {
        sections.set(tableName, {
          heading: {
            id: `${tableName}.field-list-section`,
            text: sourceLabel,
            badgeUse: rolloutStage,
            badgeText: rolloutStage
          },
          fields
        });
      }
    });
  });
};