import { fromJS } from 'immutable';
import { SUM } from '../../constants/metricTypes';
import { getDisplayParamsColumnAlias } from '../configReportGetters';
import { setFilterGroups } from '../configReportSetters';
import { getConfigByName, getConfigs, getFieldOptions, getFieldOptionsByConfigName, PRIMARY_CONFIG_NAME } from '../multiConfigReportGetters';
import { deleteColumnAliasByConfigName, setColumnAliasByObject, setColumnAliasFromMultiConfigReport, setFieldOptions } from './multiConfigReportDisplayParamSetter';
import { deleteFieldOptionsByConfigName } from './multiConfigReportVisualizationSetters';

// template for a new config
const defaultConfigTemplate = fromJS({
  metrics: [{
    property: 'new',
    metricTypes: [SUM]
  }],
  useFiscalYearInAggregation: 'true',
  dimensions: [],
  dataType: 'CRM_OBJECT',
  objectTypeId: '0-3',
  filters: {
    dateRange: {
      property: 'createdate',
      value: {
        rangeType: 'ALL',
        startDate: null,
        endDate: null,
        rollingDates: null,
        rollingDays: null,
        date: null,
        entireCurrentUnit: false
      }
    },
    owners: [],
    teams: [],
    filterGroups: [],
    query: '',
    custom: []
  },
  frequency: 'YEAR',
  configType: 'AGGREGATION'
});

/**
 * Returns a default config object with the objectTypeId set
 */
const getDefaultconfigTemplateByObjectTypeId = objectTypeId => defaultConfigTemplate.set('objectTypeId', objectTypeId);

/**
 * Sets a specific config by name in the report
 */
export const setConfigByName = (report, config, configName) => {
  const path = configName === PRIMARY_CONFIG_NAME ? ['config'] : ['reportConfigs', configName];
  return report.setIn(path, config);
};

/**
 * Deletes a whole report config and all related field options and column aliases.
 */
export const deleteConfigByName = (report, configName) => {
  let newReport = report;
  if (configName === PRIMARY_CONFIG_NAME) {
    // Get the name of the first secondary config that will replace the primary config - this one has to be the next
    // in line showed in the fieldOptions, because the FieldOptions define which config is rendered first. This is temporary
    // until we come up with a better way to handle sorting for multiconfig datawell reports.
    // (slack thread: https://hubspot.slack.com/archives/C07EGHA7HEK/p1741025427138859?thread_ts=1741024920.449419&cid=C07EGHA7HEK)
    const configToMoveName = getFieldOptions(report).map(fieldOption => fieldOption.get('dataset')).filter(dataset => dataset !== PRIMARY_CONFIG_NAME).toArray()[0];

    // Get the actual config object that will become the new primary config
    const configToMove = getConfigByName(report, configToMoveName);

    // Remove the "configToMove" from reportConfigs and set it as the new primary config
    newReport = newReport.delete('config').set('config', configToMove).deleteIn(['reportConfigs', configToMoveName]);

    // Get the fieldOptions from `configToMoveName`
    const fieldOptionsToMove = getFieldOptionsByConfigName(newReport, configToMoveName);

    // Remove both the primary and the configToMove fieldOptions
    newReport = deleteFieldOptionsByConfigName(newReport, configName);
    newReport = deleteFieldOptionsByConfigName(newReport, configToMoveName);

    // Add back the fieldOptions for the configToMove as the first one (aka primary)
    newReport = newReport.updateIn(['visualization', 'layer', 'fieldOptions'], fieldOptions => (fieldOptions || fromJS([])).unshift(fieldOptionsToMove.set('dataset', PRIMARY_CONFIG_NAME)));

    // create a new column alias with the new configs but keeping the same order
    const previousColumnAlias = getDisplayParamsColumnAlias(newReport);
    if (!previousColumnAlias) {
      return newReport;
    }

    // Rebuild column aliases maintaining the same order but with the configToMove as primary
    let updatedColumnAlias = fromJS({});
    previousColumnAlias.keySeq().toArray().forEach(key => {
      // we don't care about the primary config since it's being removed
      if (key !== 'primaryConfig') {
        if (key === configToMoveName) {
          updatedColumnAlias = updatedColumnAlias.set('primaryConfig', previousColumnAlias.get(key));
        } else {
          updatedColumnAlias = updatedColumnAlias.set(key, previousColumnAlias.get(key));
        }
      }
    });

    // Apply the updated column aliases to the report
    newReport = setColumnAliasFromMultiConfigReport(newReport, updatedColumnAlias);
  } else {
    // delete the secondary config from report
    newReport = newReport.deleteIn(['reportConfigs', configName]);
    newReport = deleteFieldOptionsByConfigName(newReport, configName);
    newReport = deleteColumnAliasByConfigName(newReport, configName);
  }
  return newReport;
};

/**
 * Returns a new multiconfig report with a new secondary config.
 * The secondary config will have the same property/aggregation from the last report config
 */
export const addAdditionalConfig = report => {
  let counter = 1;

  // find a label for the new config layer to prevent duplicates
  let newConfigName = `config_0${counter}`;
  const secondaryConfigNames = getConfigs(report).keySeq().toArray();
  while (secondaryConfigNames.includes(newConfigName)) {
    newConfigName = `config_0${counter}`;
    counter += 1;
  }

  // set the config from a template
  let newReport = report.setIn(['reportConfigs', newConfigName], getDefaultconfigTemplateByObjectTypeId(report.getIn(['config', 'objectTypeId']) || '0-3' // default to deal
  ));
  const newColumnAlias = fromJS({
    'SUM|new': {
      alias: 'New metric',
      reportColumnName: 'SUM|new',
      translationKey: null
    }
  });
  newReport = setColumnAliasByObject(newReport, newConfigName, newColumnAlias);
  newReport = setFieldOptions(newReport, newConfigName, 'new', SUM);
  return newReport;
};

/**
 * Sets the filter groups for a specific config.
 */
export const setFilterGroupsByConfigName = (report, configName, filterGroups) => {
  const updateConfig = setFilterGroups(getConfigByName(report, configName), filterGroups);
  if (configName === PRIMARY_CONFIG_NAME) {
    return report.set('config', updateConfig);
  }
  return report.setIn(['reportConfigs', configName], updateConfig);
};