import http from 'hub-http/clients/apiClient';
const getUpdateQuickFilterUrl = dashboardId => `dashboard/v2/dashboard/${dashboardId}/pinned-properties`;
const generateQuickFilterGroupsUrl = dashboardId => `dashboard/v2/dashboard/${dashboardId}/quick-filter-templates`;
export const updateQuickFilter = (dashboardId, quickFilters) => {
  return http.put(getUpdateQuickFilterUrl(dashboardId), {
    data: quickFilters
  });
};
export const fetchGeneratedQuickFilterGroups = (dashboardId, generateQuickFilterGroupsOptions) => {
  return http.get(generateQuickFilterGroupsUrl(dashboardId), {
    query: {
      quickFilterTemplate: generateQuickFilterGroupsOptions
    }
  }).then(response => response);
};