import http from 'hub-http/clients/apiClient';
const SAVED_DASHBOARD_FILTERS_BASE_URL = 'dashboard/v2/filters'; // TODO may change to /v2/filters

export const createSavedDashboardFilterSet = data => {
  return http.post(SAVED_DASHBOARD_FILTERS_BASE_URL, {
    data: Object.assign({}, data)
  });
};
export const fetchSavedFilterById = filterSetId => {
  return http.get(`${SAVED_DASHBOARD_FILTERS_BASE_URL}/${filterSetId}`);
};