import { createSlice } from '@reduxjs/toolkit';
import { updateSavedDashboardFiltersState } from './helpers';
export const savedDashboardFiltersNamespace = 'savedDashboardFilters';
export const initialState = {};

// reducer actions

const savedDashboardFiltersSlice = createSlice({
  name: savedDashboardFiltersNamespace,
  initialState,
  reducers: {
    addNewSavedDashboardFilterSet: (state, action) => {
      const {
        payload: newFilterSet
      } = action;
      const {
        dashboardId
      } = newFilterSet;
      updateSavedDashboardFiltersState(state, dashboardId, filterState => {
        const newDefault = newFilterSet.defaultFilter;
        if (newDefault) {
          filterState.filterSets = filterState.filterSets.map(filterSet => Object.assign({}, filterSet, {
            defaultFilter: false
          }));
        }
        filterState.appliedFilterSetId = newFilterSet.id;
        filterState.filterSets.push(newFilterSet);
      });
    }
  }
});
export const savedDashboardFiltersActions = savedDashboardFiltersSlice.actions;
export const savedDashboardFiltersReducer = savedDashboardFiltersSlice.reducer;