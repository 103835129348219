import { clearPersistedDashboardFilters, persistDashboardFilters } from '../../lib/dashboardFilters/persistedDashboardFilters';
import { clearDashboardFiltersQueryParams, setAdhocFiltersParam, setFrequencyFiscalYearQueryParam, setFrequencyQueryParam, setSavedFilterSetIdQueryParam } from '../../lib/dashboardFilters/dashboard-filter-query-params-util';
import { setRecentlyUsedProperties } from '../../lib/dashboardFilters/recentlyUsedProperties';
import { updateFilterOnRemoveFilterByName, updateFiltersOnAddFilter, updateFiltersOnRemoveFilter, updateFiltersOnUpdateFilter, bulkUpdateFiltersOnRemoveFilter, bulkUpdateFiltersOnAddFilter } from './utils';
import { selectDashboardFilters } from './selectors';
import { appliedDashboardFiltersActions } from './slice';
import { areFiltersSame } from '../../lib/dashboardFilters/types';
import { getDefaultFilterSet, getFilterSetById } from '../../lib/savedDashboardFilters';
export const filterQueryParamsMiddleware = history => ({
  getState
}) => next => action => {
  const persistNonSavedAppliedFilters = (dashboardId, nextAppliedFilters) => {
    persistDashboardFilters(dashboardId, nextAppliedFilters);
    setAdhocFiltersParam({
      history,
      dashboardFilters: nextAppliedFilters,
      dashboardId
    });
  };
  if (appliedDashboardFiltersActions.initializeDashboardFilters.match(action)) {
    const {
      dashboardId,
      filters,
      filterSetId,
      filterSets,
      frequency,
      useFiscalYear
    } = action.payload;
    const maybeAppliedFilterSet = filterSets ? filterSetId != null ? getFilterSetById(filterSets, filterSetId) : getDefaultFilterSet(filterSets) : undefined;

    // Adhoc filters to be used in url param override
    const filtersLessSavedFilters = maybeAppliedFilterSet ? filters.filter(filter => !maybeAppliedFilterSet.filters.some(filterSetFilter => areFiltersSame(filterSetFilter, filter))) : filters;
    const nextFilterSetIdQueryParam = maybeAppliedFilterSet ? maybeAppliedFilterSet.defaultFilter ? undefined : maybeAppliedFilterSet.id : undefined;
    const maybeAppliedFilterSetFrequency = maybeAppliedFilterSet ? maybeAppliedFilterSet.frequency : undefined;
    const nextFrequencyParam = maybeAppliedFilterSetFrequency !== frequency ? frequency : undefined;
    persistDashboardFilters(dashboardId, filters);
    setFrequencyQueryParam({
      history,
      frequency: nextFrequencyParam
    });
    const maybeAppliedFilterSetFiscalYear = maybeAppliedFilterSet ? maybeAppliedFilterSet.useFiscalYear : undefined;
    const nextFrequencyFiscalYearParam = maybeAppliedFilterSetFiscalYear !== useFiscalYear ? useFiscalYear : undefined;
    setFrequencyFiscalYearQueryParam({
      history,
      useFiscalYear: nextFrequencyFiscalYearParam
    });
    setSavedFilterSetIdQueryParam({
      history,
      filterSetId: nextFilterSetIdQueryParam
    });
    setAdhocFiltersParam({
      history,
      dashboardFilters: filtersLessSavedFilters,
      dashboardId
    });
  }
  if (appliedDashboardFiltersActions.loadMigratedLegacyFilters.match(action)) {
    const {
      dashboardFilters,
      dashboardId,
      frequency
    } = action.payload;
    setFrequencyQueryParam({
      history,
      frequency
    });
    setAdhocFiltersParam({
      history,
      dashboardFilters,
      dashboardId
    });
    persistDashboardFilters(dashboardId, dashboardFilters);
  }
  if (appliedDashboardFiltersActions.addDashboardFilter.match(action)) {
    const {
      dashboardId,
      filterData,
      dataSource,
      property
    } = action.payload;
    const prevFilters = selectDashboardFilters(dashboardId)(getState());
    const updatedFilters = updateFiltersOnAddFilter(prevFilters, filterData);
    persistNonSavedAppliedFilters(dashboardId, updatedFilters);
    if (dataSource && property) {
      setRecentlyUsedProperties({
        dataSource,
        property
      }).catch(console.error);
    }
  }
  if (appliedDashboardFiltersActions.updateDashboardFilter.match(action)) {
    const {
      dashboardId,
      index,
      filterData
    } = action.payload;
    const prevFilters = selectDashboardFilters(dashboardId)(getState());
    const updatedFilters = updateFiltersOnUpdateFilter(prevFilters, index, filterData);
    persistNonSavedAppliedFilters(dashboardId, updatedFilters);
  }
  if (appliedDashboardFiltersActions.addGroupDashboardFilter.match(action)) {
    const {
      dashboardId,
      quickFilterGroupOperators,
      systemGenerated,
      quickFilterGroupName
    } = action.payload;
    const prevFilters = selectDashboardFilters(dashboardId)(getState());
    const updatedFilters = bulkUpdateFiltersOnAddFilter(prevFilters, quickFilterGroupOperators, systemGenerated, quickFilterGroupName);
    persistNonSavedAppliedFilters(dashboardId, updatedFilters);
  }
  if (appliedDashboardFiltersActions.removeDashboardFilter.match(action)) {
    const {
      dashboardId,
      index
    } = action.payload;
    const prevFilters = selectDashboardFilters(dashboardId)(getState());
    const updatedFilters = updateFiltersOnRemoveFilter(prevFilters, index);
    persistNonSavedAppliedFilters(dashboardId, updatedFilters);
  }
  if (appliedDashboardFiltersActions.removeDashboardFilterByName.match(action)) {
    const {
      dashboardId,
      dataSource,
      propertyName
    } = action.payload;
    const prevFilters = selectDashboardFilters(dashboardId)(getState());
    const updatedFilters = updateFilterOnRemoveFilterByName(prevFilters, dataSource, propertyName);
    persistNonSavedAppliedFilters(dashboardId, updatedFilters);
  }
  if (appliedDashboardFiltersActions.removeGroupDashboardFilter.match(action)) {
    const {
      dashboardId,
      properties
    } = action.payload;
    const prevFilters = selectDashboardFilters(dashboardId)(getState());
    const updatedFilters = bulkUpdateFiltersOnRemoveFilter(prevFilters, properties);
    persistNonSavedAppliedFilters(dashboardId, updatedFilters);
  }
  if (appliedDashboardFiltersActions.removeCurrentDashboardFilters.match(action)) {
    const {
      dashboardId
    } = action.payload;
    persistDashboardFilters(dashboardId, []);
    clearDashboardFiltersQueryParams({
      history
    });
  }
  if (appliedDashboardFiltersActions.removeAllDashboardFilters.match(action)) {
    clearPersistedDashboardFilters();
    clearDashboardFiltersQueryParams({
      history
    });
  }
  if (appliedDashboardFiltersActions.updateFrequency.match(action)) {
    const {
      frequency
    } = action.payload;
    setFrequencyQueryParam({
      history,
      frequency
    });
  }

  // only add frequency param if applied frequency is not the same as the applied filters sets frequency
  if (appliedDashboardFiltersActions.updateFrequencyUseFiscalYear.match(action)) {
    const {
      useFiscalYear
    } = action.payload;
    setFrequencyFiscalYearQueryParam({
      history,
      useFiscalYear
    });
  }
  next(action);
};